import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import Report from "../pages/report/Report";
import DISC from "../pages/report/DISC";
import ReportLearnFushion from "../pages/report/ReportLearnFushion";
import ReportOurHumanBody from "../pages/report/ReportOurHumanBody";
import ReportDiscoverYourself from "../pages/report/ReportDiscoverYourself";
import QuickSummary from "../pages/report/QuickSummary";
import ExportReport from "../pages/report/ExportReport";
import ReportSnapshotHealth from "../pages/report/ReportSnapshotHealth";
import ReportSpeacialConsult from "../pages/report/ReportSpeacialConsult";
import ReportNewJourney from "../pages/report/ReportNewJourney";
import FullReportCategory from "../pages/report/fullReport/FullReportCategory";
import FullReportSubCategory from "../pages/report/fullReport/FullReportSubCategory";
import FullReportRecommend from "../pages/report/fullReport/FullReportRecommend";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route
          path="/report/:uid/:userId/:reportConfId"
          component={Report}
          exact
        />
        <Route path="/report/disc" component={DISC} exact />
        {/* <Route
          path="/report/learn-about-fushion"
          component={ReportLearnFushion}
          exact
        />
        <Route
          path="/report/our-human-body"
          component={ReportOurHumanBody}
          exact
        />
        <Route
          path="/report/discover-yourself"
          component={ReportDiscoverYourself}
          exact
        /> */}
        <Route path="/report/summary" component={QuickSummary} exact />
        <Route
          path="/report/download/:uid/:userId/:reportConfId"
          component={ExportReport}
          exact
        />
        {/* <Route
          path="/report/snapshot-your-health"
          component={ReportSnapshotHealth}
          exact
        />
        <Route
          path="/report/special-consult"
          component={ReportSpeacialConsult}
          exact
        />
        <Route
          path="/report/start-new-journey"
          component={ReportNewJourney}
          exact
        />
        <Route
          path="/full-report/:category"
          component={FullReportCategory}
          exact
        />
        <Route
          path="/full-report/:category/:subCategory"
          component={FullReportSubCategory}
          exact
        />
        <Route
          path="/full-report/:category/:subCategory/recommend"
          component={FullReportRecommend}
          exact
        /> */}
        {/* <Redirect path="*" to="/404" exact />  */}
        <Redirect
          path="*"
          to="/report/REcKyplT6GVH03LN5GTG/OY4PYaP3voRFQyvpkAA5B63AzJw1/1"
          exact
        />
      </Switch>
    </Router>
  );
};

export default Routes;
