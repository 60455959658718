import styled from 'styled-components'

const Div = styled.footer`
  padding: 14px;
  font-size: 13px;
  text-align: center;
  color: var(--off-black);
`

const Footer = () => {
  return <Div>COPY RIGHT| 2BSIMPLE</Div>
}

export default Footer
