import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'

import FullReportLayout from '../../../components/layouts/FullReportLayout'

import imageDotChart from '../../../assets/images/report/dot-chart.png'

const Div = styled.div`
  .head-category {
    > .title {
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 56px;
      line-height: 48px;
      color: var(--off-black);
    }

    > .sub-title {
      margin-top: 16px;
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 24px;
      line-height: 32px;
      color: var(--off-black);
    }

    > .desc {
      margin-top: 8px;
    }

    .summary-wrapper {
      display: flex;
      margin-top: 56px;

      .left {
        flex: 1;
        font-size: 18px;

        > div {
          position: relative;
          padding-left: 24px;
          margin-bottom: 16px;

          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            left: 0;
            top: 6px;
            border-radius: 50%;
          }

          &.red:after {
            background: #f03f3b;
          }

          &.yellow:after {
            background: #ffb800;
          }

          &.green:after {
            background: #27ae8e;
          }
        }
      }

      .right {
        flex: 1;
        max-width: 430px;

        img {
          width: 100%;
        }
      }
    }
  }

  .category-wrapper {
    .category-type {
      margin-top: 64px;

      .type-title {
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 56px;
        line-height: 48px;
        color: var(--off-black);
        border-left: 8px solid #f03f3b;
        padding-left: 16px;
      }

      .type-desc {
        margin-top: 16px;
      }

      .type-list {
        margin-top: 18px;

        > div {
          margin-top: 8px;

          .circle {
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #f03f3b;
            color: var(--off-white);
            margin-right: 12px;
            text-align: center;
          }
        }
      }

      .type-sub-desc {
        margin-top: 24px;
      }

      .list-wrapper {
        margin-top: 16px;

        .list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 18px 0;
          border-bottom: 1px solid var(--off-black);

          .left {
            .sub-category {
              font-family: 'kelson_sansbold';
              text-transform: uppercase;
              font-size: 24px;
              line-height: 32px;
              color: var(--off-black);
            }

            .detail {
              font-size: 14px;
              color: #f03f3b;
            }
          }

          .link {
            padding: 6px 35px;
            font-family: 'kelson_sansbold';
            text-transform: uppercase;
            font-size: 14px;
            line-height: 20px;
            color: var(--off-black);
            border: 1px solid var(--off-black);
            text-decoration: none;
          }
        }
      }

      &.type-yellow {
        .type-title {
          border-color: #ffb800 !important;
        }

        .type-list .circle {
          background: #ffb800 !important;
        }

        .list-wrapper .detail {
          color: #ffb800 !important;
        }
      }

      &.type-green {
        .type-title {
          border-color: #27ae8e !important;
        }

        .type-list .circle {
          background: #27ae8e !important;
        }

        .list-wrapper .detail {
          color: #27ae8e !important;
        }
      }
    }
  }
`

const FullReportCategory = () => {
  const { category } = useParams()
  return (
    <FullReportLayout>
      <Div>
        <div className="head-category">
          <div className="title">BEAUTY AND SKIN</div>
          <div className="sub-title">Your Skin Risk & Abilities</div>
          <div className="desc">
            เปิดเผยแนวโน้มสุขภาพผิวของคุณจาก DNA ตรวจสอบความไวของผิวต่อปัจจัยรบกวนต่างๆ ที่ทําลายผิว
            ค้นหาความเสี่ยงที่คุณควรหลีกเลี่ยง เป็นพิเศษ และดูวิธีการดูแลผิวที่เหมาะกับ DNA ของคุณ
          </div>
          <div className="summary-wrapper">
            <div className="left">
              <div className="green">พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป</div>
              <div className="yellow">พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป</div>
              <div className="red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
            </div>
            <div className="right">
              <img src={imageDotChart} />
            </div>
          </div>
        </div>
        <div className="category-wrapper">
          <div className="category-type type-red">
            <div className="type-title">Critical</div>
            <div className="type-desc">Base on your genetic results. you have</div>
            <div className="type-list">
              <div>
                <span className="circle">2</span>
                <span>Domain Higher-than-average Risks</span>
              </div>
              <div>
                <span className="circle">1</span>
                <span>Domain Lower-than-average Ability</span>
              </div>
            </div>
            <div className="type-sub-desc">
              Access Full report below to get your full genetic result, our recommendation to
              improve your Skin Health.
            </div>
            <div className="list-wrapper">
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">Photo Aging Risk</div>
                  <div className="detail">Higher-than-average Risks</div>
                </div>
                <Link className="link" to={`/full-report/${category}/photo-aging`}>
                  Full report
                </Link>
              </div>
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">UV Allergy Risk</div>
                  <div className="detail">Higher-than-average Risks</div>
                </div>
                <Link className="link" to={`/full-report/${category}/uv-allery`}>
                  Full report
                </Link>
              </div>
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">Anti-freckle Ability</div>
                  <div className="detail">Higher-than-average Risks</div>
                </div>
                <Link className="link" to={`/full-report/${category}/anti-freckle`}>
                  Full report
                </Link>
              </div>
            </div>
          </div>
          <div className="category-type type-yellow">
            <div className="type-title">Average</div>
            <div className="type-desc">Base on your genetic results. you have</div>
            <div className="type-list">
              <div>
                <span className="circle">3</span>
                <span>Average Risks</span>
              </div>
              <div>
                <span className="circle">3</span>
                <span>Average Ability</span>
              </div>
            </div>
            <div className="type-sub-desc">
              Access Full report to get your full genetic result, our recommendation to maintian
              your Skin heath.
            </div>
            <div className="list-wrapper">
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">Photo Aging Risk</div>
                  <div className="detail">On Average</div>
                </div>
                <Link className="link" to={`/full-report/${category}/photo-aging`}>
                  Full report
                </Link>
              </div>
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">UV Allergy Risk</div>
                  <div className="detail">On Average</div>
                </div>
                <Link className="link" to={`/full-report/${category}/uv-allery`}>
                  Full report
                </Link>
              </div>
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">Anti-freckle Ability</div>
                  <div className="detail">On Average</div>
                </div>
                <Link className="link" to={`/full-report/${category}/anti-freckle`}>
                  Full report
                </Link>
              </div>
            </div>
          </div>
          <div className="category-type type-green">
            <div className="type-title">Fine</div>
            <div className="type-desc">Base on your genetic results. you have</div>
            <div className="type-list">
              <div>
                <span className="circle">1</span>
                <span>Lower-than-average Risks</span>
              </div>
            </div>
            <div className="type-sub-desc">
              Access Full report below to get your full genetic result, our recommendation to
              improve your Skin Health.
            </div>
            <div className="list-wrapper">
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">Photo Aging Risk</div>
                  <div className="detail">Lower-than-average</div>
                </div>
                <Link className="link" to={`/full-report/${category}/photo-aging`}>
                  Full report
                </Link>
              </div>
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">UV Allergy Risk</div>
                  <div className="detail">Lower-than-average</div>
                </div>
                <Link className="link" to={`/full-report/${category}/uv-allery`}>
                  Full report
                </Link>
              </div>
              <div className="list-item">
                <div className="left">
                  <div className="sub-category">Anti-freckle Ability</div>
                  <div className="detail">Lower-than-average</div>
                </div>
                <Link className="link" to={`/full-report/${category}/anti-freckle`}>
                  Full report
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </FullReportLayout>
  )
}

export default FullReportCategory
