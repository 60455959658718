import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import ReportLayout from '../../components/layouts/ReportLayout'
import NextButton from '../../components/common/button/NextButton'

import imageBanner from '../../assets/images/report/banner-learn-more.png'

const Div = styled.div`
  .section {
    .section-wrapper {
      display: flex;
      margin: 0 -24px;
    }

    .left {
      flex: 1;
      margin: 0 24px 24px;
    }

    .right {
      flex: 1;
      margin: 0 24px 24px;
    }

    .title {
      font-family: 'kelson_sansbold';
      font-size: 56px;
      line-height: 64px;
      text-transform: uppercase;
    }

    .sub-title {
      font-family: 'kelson_sansbold';
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
    }

    &.section-top {
      .sub-title {
        margin-top: 212px;
      }

      .content {
        margin-top: 16px;
      }

      .right {
        img {
          width: 100%;
        }
      }
    }

    &.section-bottom {
      margin-top: 80px;
      padding-top: 73px;
      border-top: 1px solid var(--off-black);

      .group {
        margin-top: 48px;

        .group-title {
          font-size: 20px;
          font-weight: bold;
        }

        &:first-child {
          margin-top: 0px;
        }
      }

      button {
        margin-top: 56px;
        width: 100%;
      }
    }
  }
`

const ReportLearnFushion = () => {
  const history = useHistory()
  return (
    <ReportLayout>
      <Div>
        <div className="section section-top">
          <div className="section-wrapper">
            <div className="left">
              <div className="title">Explore Our Healthy System: Body</div>
              <div className="sub-title">
                In order to be physically healthy  you need to be mentally healthy and in order to
                be mentally healthy  you need to be physically healthy 
              </div>
              <div className="content">
                <p>
                  The human body is a complex network of cells tissues and organs that together Make
                  life possible
                </p>
                <p>
                  Ten major systems are responsible for the body's functions , skeletal muscular,
                  cardiovascular, nervous, endocrine, lymphatic, respiratory digestive urinary,
                  Reproductive.
                </p>
                <p>
                  The skeletal, muscular, cardiovascular and nervous systems in particular create an
                  infrastructure that facilitates the other systems. These infrastructure created by
                  neurons blood muscles and bone. Allows three other systems to regulate the body's
                  environment the endocrine lymphatic and urinary systems. All of these systems
                  require energy to function and that's where the respiratory and digestive. No
                  matter the roll size or shape of any of the body systems each began with a
                  reproductive system. This system is responsible for creating life. Humans are
                  complicated organisms. But when our 10 major organ systems are healthy. They
                  ensure our well-being
                </p>
              </div>
            </div>
            <div className="right">
              <img src={imageBanner} />
            </div>
          </div>
        </div>
        <div className="section section-bottom">
          <div className="section-wrapper">
            <div className="left">
              <div className="title">
                Feeding, exercising  and Sleeping  is the way to get the best performance in all
                areas of life
              </div>
            </div>
            <div className="right">
              <div className="content">
                <div className="group">
                  <div className="group-title">
                    Feeding: There are four main foods that your body system needs
                  </div>
                  <p>
                    1. oxygen : You need to get good oxygen pumping around your entire system the
                    brain cells and the body itself
                  </p>
                  <p>
                    2. Good nutrition : you need good food your brain cells and your body are made
                    of what you put in your mouth good food-good brain-good body , junk food-junk
                    brain-junk body 
                  </p>
                  <p>
                    3. Develop your senses : Your eyes you'll see like an artist . Your ears must
                    hear like a musician , so you must feed this sensory mechanism and then the
                    brain and the body erupt with health
                  </p>
                  <p>
                    4. Relation which is a really nice one . Affection and love when you have French
                    perfection and love your brain lights up your body relaxes you breathe more
                    easily everything works better, brain and body
                  </p>
                </div>
                <div className="group">
                  <div className="group-title">Fitness:</div>
                  <p>
                    If you are cardiovascularly fit and you get that by by running by swimming by
                    rowing by working out in the gym by fast walking anything that keeps the heart
                    beating for 20 minutes or more. When you are cardiovascularly fit you have a
                    bigger stronger heart pumping more blood every second of your life.  Formula one
                    grade fuel into your million million brain cells and around your magnificent
                    body, your body must be flexible
                  </p>
                  <p>
                    If you are rigid then the blood can't flow. The neurological impulses don't
                    flow, so you become literally rigid. When you are flexible then the blood and
                    the neurophysiological messages can flow.
                  </p>
                </div>
                <div className="group">
                  <div className="group-title">Sleep:</div>
                  <p>
                    Sleep plays a vital role in good health and well-being throughout your life.
                    Getting enough quality sleep at the right times can help protect your mental
                    health, physical health, quality of life, and safety.
                  </p>
                  <p>
                    The way you feel while you're awake depends in part on what happens while you're
                    sleeping. During sleep, your body is working to support healthy brain function
                    and maintain your physical health. In children and teens, sleep also helps
                    support growth and development.
                  </p>
                  <p>
                    The damage from sleep deficiency can occur in an instant (such as a car crash),
                    or it can harm you over time. For example, ongoing sleep deficiency can raise
                    your risk for some chronic health problems. It also can affect how well you
                    think, react, work, learn, and get along with others.
                  </p>
                </div>
              </div>
              <NextButton onClick={() => history.push('/report/our-human-body')}>
                Explore our body
              </NextButton>
            </div>
          </div>
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportLearnFushion
