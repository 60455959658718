import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FullReportLayout from '../../../components/layouts/FullReportLayout'

import imageResult1 from '../../../assets/images/report/your-result1.png'
import imageResult2 from '../../../assets/images/report/your-result2.png'
import iconScoreGraph from '../../../assets/images/report/score-graph.svg'

const Div = styled.div`
  .head-sub-category {
    > .title {
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 56px;
      line-height: 48px;
      color: var(--off-black);
    }

    > .sub-title {
      margin-top: 16px;
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 24px;
      line-height: 32px;
      color: var(--off-black);
    }

    > .desc {
      margin-top: 8px;
    }
  }

  .content-group {
    margin-top: 54px;
    border-top: 2px solid var(--off-black);

    .group-title {
      margin-top: 32px;
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 32px;
      line-height: 40px;
      color: var(--off-black);

      svg {
        font-size: 24px;
        margin-right: 24px;
        transform: rotate(-45deg);
      }
    }

    .group-body {
      margin-top: 40px;
      font-size: 16px;
    }

    .result-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 34px -8px 0;

      .left {
        flex: 2;
        margin: 6px 8px;
      }

      .right {
        flex: 1;
        margin: 6px 8px;
        min-width: 280px;
      }

      .risk-title {
        position: relative;
        font-weight: bold;
        color: var(--off-black);
        padding-left: 24px;

        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 16px;
          height: 16px;
          left: 0;
          top: 3px;
          border-radius: 50%;
        }

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }
      }

      .risk-desc {
        margin-top: 16px;
      }

      .score-title {
        display: flex;
        justify-content: space-between;

        .score-text {
          font-weight: bold;
          color: var(--off-black);
        }

        span {
          margin-left: 4px;
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: var(--line);

          &.active {
            background: var(--off-black);
          }
        }
      }

      .score-body {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        .score-graph {
          margin-right: 16px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          text-align: center;
          color: var(--off-white);
          background: var(--off-black);

          img {
            margin-top: 16px;
          }
        }

        .score-detail {
          flex: 1;
          font-size: 14px;
        }
      }
    }

    .result-image {
      display: flex;
      justify-content: space-between;
      margin: 32px -4px 0;

      img {
        width: 100%;
        min-width: 0;
        margin: 0 4px;
      }
    }

    .nav-link {
      display: inline-block;
      margin-top: 32px;
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 32px;
      line-height: 40px;
      color: var(--off-black);
      text-decoration: none;

      svg {
        font-size: 24px;
        margin-right: 24px;
      }
    }
  }
`

const FullReportSubCategory = () => {
  const { category, subCategory } = useParams()
  return (
    <FullReportLayout>
      <Div>
        <div className="head-sub-category">
          <div className="title">PHOTO AGING RISK</div>
          <div className="sub-title">ผิวหนังเสื่อมสภาพ จากการโดนแสงแดด (UVA) </div>
          <div className="desc">
            เปิดเผยแนวโน้มสุขภาพผิวของคุณจาก DNA ตรวจสอบความไวของผิวต่อปัจจัยรบกวนต่างๆ ที่ทําลายผิว
            ค้นหาความเสี่ยงที่คุณควรหลีกเลี่ยง เป็นพิเศษ และดูวิธีการดูแลผิวที่เหมาะกับ DNA ของคุณ
          </div>
        </div>
        <div className="content-sub-category">
          <div className="content-group">
            <div className="group-title">
              <FontAwesomeIcon icon={['fas', 'arrow-right']} />
              YOUR RESULT
            </div>
            <div className="group-body">
              <div className="result-wrapper">
                <div className="left">
                  <div className="risk-title red">มีความเสี่ยงสูงกว่าค่าเฉลี่ยของคนทั่วไป</div>
                  <div className="risk-desc">
                    จากการวิเคราะห์ DNA ของคุณ Chavalit (Sample ID 123445667)
                    <br />
                    เราตรวจพบการเปลี่ยนแปลงของชนิดนิวคลีโอไทป์ 1 ตําแหน่ง (SNPS Rs322458)
                    <br />
                    ที่มีความสัมพันธ์กับการเกิดจุดดํา หมองคล้ํา
                    <br />
                    ริ้วรอยที่บ่งบอกความเสื่อมของผิวอันเนื่องมาจากการโดนแสงแดด
                  </div>
                </div>
                <div className="right">
                  <div className="score-title">
                    <div className="score-text">CONFIDENCE SCORE</div>
                    <div className="score-value">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span className="active"></span>
                      <span></span>
                    </div>
                  </div>
                  <div className="score-body">
                    <div className="score-graph">
                      <img src={iconScoreGraph} />
                    </div>
                    <div className="score-detail">
                      <div className="detail-title">STRONG RESEARCH</div>
                      <div className="detail-desc">
                        High quality studies performed with positive results
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="result-image">
                <img src={imageResult1} />
                <img src={imageResult2} />
                <img src={imageResult1} />
                <img src={imageResult2} />
              </div>
            </div>
          </div>
          <div className="content-group">
            <div className="group-title">
              <FontAwesomeIcon icon={['fas', 'arrow-right']} />
              ABOUT
            </div>
            <div className="group-body">
              <p>
                เคยรู้สึกว่าผิวมีริ้วรอยก่อนวัย บ้างไหม? รู้สึกว่าผิวหยาบ ไม่ยืดหยุ่น ผิวหมองคล้ํา
                ริ้วรอยลึก จุดเม็ดสี ผิวไม่สม่ําเสมอไหม? อาจเป็นอาการของ ผิวหนังที่เสื่อมสภาพ
                จากการโดนแสงแดด
              </p>
              <p>
                80% ของความชราของมนุษย์มาจาก "ริ้วรอยphoto aging"
                ปัจจัยสําคัญซึ่งก่อให้เกิดริ้วรอยของวัย / photo aging คือ รังสีอัลตราไวโอเลต
                ที่จะซึมเข้าไปในผิวหนังและค่อย ๆ ทําลายคอลลาเจนและความยืดหยุ่นในผิวหนัง
                เลยทําให้ผิวหนังมีความหยาบกร้าน ริ้วรอยลึก หมองคล้ํา และมีเม็ดสีที่ไม่สม่ําเสมอ
                ถึงแม้จะไม่ได้ก่อให้เกิดในทันทีที่โดนแสงแดดแต่การพบเจอแสงแดดเป็นประจําจะทําให้การเกิดริ้วรอยของวัยมาเร็วขึ้น
              </p>
              <p>
                และพันธุกรรมก็เป็นหนึ่งปัจจัยหลักที่สําคัญของการเกิดริ้วรอยของวัย
                เพราะจีโนไทป์ที่ต่างกันจะเห็นได้ชัดถึงโอกาสการเกิดphoto agingสูงหรือต่ํา
                ที่จะช่วยให้วางแผนรับมือและป้องกันได้ดียิ่งขึ้น
              </p>
              <p>
                ปัจจัยที่่ก่อให้เกิดอาการ
                <br />
                - ปัจจัยทางพันธุกรรม: พาหะของจีโนไทป์ที่เฉพาะเจาะจงมีโอกาสเกิดภาพถ่ายสูงกว่า
                <br />- การได้รับแสงแดด
              </p>
            </div>
          </div>
          <div className="content-group">
            <div className="group-title">
              <FontAwesomeIcon icon={['fas', 'arrow-right']} />
              EFFECT
            </div>
            <div className="group-body">
              <p>
                คุณอาจมีริ้วรอยของวัย มีร่องรอยความแก่ของผิวหนังก่อนวัยอันควร*
                เมื่อเทียบกับผู้มีความเสี่ยงน้อยกว่าเมื่ออยู่ในสภาพแวดล้อมแบบเดียวกัน
              </p>
              <p>
                จะเกิดอะไรขึ้น หากละเลยการดูแลตนเอง
                <br />
                - ร่องรอยผิวที่ถูกทําลายจะพัฒนาเป็นฝ้า กระ ซึ่งรักษาได้ยาก
                <br />
                - ผิวเสื่อมรุนแรง จนมีโอกาสพัฒนาเป็นเซลล์มะเร็ง
                <br />- ผิวเสียถาวร
              </p>
              <p>( *เฉลี่ยแล้วคนทั่วไปมักเริ่มมีริ้วรอยจากความแก่ของผิว เมื่ออายุ 25 ปีขึ้นไป )</p>
            </div>
          </div>
          <div className="content-group">
            <Link className="nav-link" to={`/full-report/${category}/${subCategory}/recommend`}>
              <FontAwesomeIcon icon={['fas', 'arrow-right']} />
              Recommended
            </Link>
          </div>
        </div>
      </Div>
    </FullReportLayout>
  )
}

export default FullReportSubCategory
