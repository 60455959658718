import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/common/button/Button";

import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BarChart from "components/common/chart/BarChart";

import imageBanner from "../../assets/images/report/banner-promise.png";
import icon1 from "../../assets/images/report/icon-promise1.svg";
import icon3 from "../../assets/images/report/icon-promise3.svg";
import icon4 from "../../assets/images/report/icon-promise4.svg";

import defaultImg from "../../assets/images/report/default-image.jpg";
import formatValue from "utils/formatValue";

import {
  getRecommendById,
  getDiscSummaryById,
  getQuickSummaryById,
  setUIdUserIdAndReportConfId,
} from "redux/actions/report";

const StyledBanner = styled.div`
  background: var(--off-black);
  padding: 20px 30px;

  > .wrapper {
    display: flex;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    font-family: "kelson_sansbold";
    text-transform: uppercase;
    color: var(--off-white);

    .left {
      flex: 1;

      .title {
        font-size: 36px;
        height: 56px;
        text-transform: uppercase;
      }

      .total-report {
        font-size: 16px;

        span {
          font-size: 160px;
          line-height: 192px;
        }
      }

      button {
        border: 1px solid var(--off-white);
        width: 330px;
      }
    }

    .right {
      flex: 1;
      margin-top: 200px;

      .catagory {
        font-size: 160px;
        line-height: 142px;

        span {
          font-size: 24px;
          line-height: 32px;
          vertical-align: top;
        }
      }

      button {
        margin-top: 32px;
        width: 320px;
      }
    }
  }
`;

const StyledDISCBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 520px;
  background: url("${imageBanner}") no-repeat center center;
  background-size: cover;
  text-align: center;

  .title {
    font-family: "kelson_sansbold";
    text-transform: uppercase;
    font-size: 32px;
  }

  .title2 {
    font-family: "kelson_sansbold";
    text-transform: uppercase;
    font-size: 48px;
  }

  .desc {
    margin: 12px auto 0;
    max-width: 700px;
    font-size: 18px;
  }
`;

const Banner = () => {
  const { quickSummary, uid, userId, reportConfId } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();
  const [quickData, setQuickData] = useState(quickSummary);

  useEffect(() => {
    dispatch(getQuickSummaryById(uid, userId, reportConfId));
  }, []);

  useEffect(() => {
    setQuickData(quickSummary);
  }, [quickSummary]);

  return (
    <StyledBanner>
      <div className="wrapper">
        <div className="left">
          <div className="title">Matching score</div>
          <div className="total-report">
            <span>{quickData?.matching_score}%</span>
          </div>
        </div>
        <div className="right">
          <div className="catagory">
            {quickData?.percenttile_order}
            <span>{quickData?.ordinal_suffix}</span>
          </div>
          <Button
            // onClick={() => history.push("/report/snapshot-your-health")}
            invert
          >
            PECENTILE
          </Button>
        </div>
      </div>
    </StyledBanner>
  );
};

const DISCBanner = () => {
  const { discSummary, uid, userId, reportConfId } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();
  const [discData, setDiscData] = useState(discSummary);

  useEffect(() => {
    console.log(170, uid);
    console.log(171, userId);
    dispatch(getDiscSummaryById(uid, userId, reportConfId));
  }, []);

  useEffect(() => {
    setDiscData(discSummary);
    console.log(172, discData);
  }, [discSummary]);

  return (
    <StyledDISCBanner>
      {discData?.disc_result != {} && (
        <div className="title2">
          ไม่มีผล DISC Result <br />
          เนื่องจากไม่มีในรอบสอบนี้
        </div>
      )}
      <div className="desc">{discData?.disc_description_medium}</div>
    </StyledDISCBanner>
  );
};

const CategoryLink = ({ children, ...props }) => {
  return (
    <HashLink className="category-link" {...props}>
      <FontAwesomeIcon icon={["fas", "arrow-right"]} />
      {children}
    </HashLink>
  );
};

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 80px;

  > .test-detail {
    flex: 1;

    .profile-name,
    .test-name,
    .kit-name {
      font-family: "kelson_sansbold";
      text-transform: uppercase;
    }

    .profile-name {
      font-size: 56px;
      line-height: 64px;
      color: var(--off-black);
      font-weight: bold;
    }

    .test-name {
      margin-top: 8px;
      font-size: 32px;
      color: var(--text-body);
    }

    .test-summary {
      margin-top: 32px;
      font-size: 18px;
    }

    .test-kit {
      margin-top: 92px;

      .kit-name {
        font-size: 28px;
        color: var(--off-black);
        font-weight: bold;
      }

      .kit-data {
        font-size: 22px;
        margin-top: 16px;
      }
    }
  }

  > .information {
    flex: 1;
    max-width: 320px;
    text-align: right;
    margin-top: 360px;
    font-size: 14px;

    .img-desc {
      margin-top: 20px;
      margin-left: auto;
      width: 262px;
      height: 88px;
      background: var(--disabled);
    }

    .desc2 {
      margin-top: 16px;
    }

    .desc3 {
      display: flex;
      margin-top: 12px;
      justify-content: flex-end;
      align-items: center;
      text-align: left;

      .img {
        width: 56px;
        height: 56px;
        background: var(--disabled);
        border-radius: 50%;
        margin-right: 12px;
      }
    }

    > button {
      width: 100%;
      margin-top: 64px;
    }
  }
`;

const DivQuickSummary = styled.div`
  .pagebreak {
    page-break-before: always;
  }
`;

const DivDISC = styled.div`
  .pagebreak {
    page-break-before: always;
  }

  .content {
    padding: 20px 20px;
  }
`;

const WrapperQuickSummary = styled.div`
  .summary-wrapper {
    display: flex;
    margin: 10px 0;

    .left {
      flex: 1;
      margin: 0 64px;

      .sub-title {
        font-family: "kelson_sansbold";
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
      }

      .title {
        margin-top: 8px;
        font-family: "kelson_sansbold";
        font-size: 56px;
        line-height: 64px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .desc {
        margin-top: 16px;
        font-size: 18px;
      }
    }

    .right {
      flex: 1;
      margin: 0 64px;

      .row {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        border-top: 1px solid var(--off-black);

        &:first-child {
          border-top: none;
        }

        .category {
          width: 50%;
        }
      }

      a {
        color: var(--off-black);
        text-decoration: none;

        svg {
          margin-right: 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .chart-wrapper {
    margin: 67px 25px;
    .title {
      font-size: 18px;
    }

    .information-wrapper {
      display: flex;
      margin: 24px 25px;

      .information {
        position: relative;
        flex: 1;
        font-size: 18px;
        padding-left: 32px;
        padding-right: 12px;

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          top: 0;
          left: -10px;
        }
      }
    }
  }

  .cetegory-wrapper {
    margin: 25px 80px;

    .cetegory {
      display: flex;
      margin: 0 -8px;

      .left {
        flex: 1;
        margin: 8px 5px 30px;
      }

      .right {
        flex: 1;
        margin: 0 8px 20px;
      }

      .title {
        margin-bottom: 24px;
        font-family: "kelson_sansbold";
        font-size: 56px;
        line-height: 56px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .btn-full-report {
        padding: 8px 24px;
        font-family: "kelson_sansbold";
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--off-black);
        border: 1px solid var(--off-black);
        text-decoration: none;
      }

      .information {
        position: relative;
        font-size: 24px;
        padding: 9px 8px 9px 32px;
        border-top: 1px solid var(--off-black);

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top: 15px;
          left: 6px;
        }
      }

      .detail {
        margin-top: 24px;
        font-size: 18px;
      }
    }
  }
`;

const WrapperPositionPercentile = styled.div`
  margin: 0 80px;
  .section-bottom {
    padding-top: 80px;

    .section-wrapper {
      display: flex;
      margin: 0 -24px;

      .left {
        flex: 1;
        margin: 0 24px;
        font-family: "kelson_sansbold";
        font-size: 60px;
        line-height: 70px;
        text-transform: uppercase;
      }

      .right {
        flex: 1;
        margin: 0 24px;

        .content {
          flex: 1;
          font-family: "kelson_sansbold";
          font-size: 40px;
          line-height: 50px;
          text-transform: uppercase;
        }
        .sub-content {
          font-size: 24px;
          font-weight: lighter;
        }
        button {
          width: 100%;
          margin-top: 48px;
        }
      }
    }
  }
`;

const WrapperGraph = styled.div`
  .chart {
    margin-top: 55px;
    margin-left: 20px;
    text-align: center;
    font-size: 24px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .chart-container {
    width: 100%;

    .chart-box {
      canvas {
        padding-right: 110px;
        height: 50vh;
      }
    }
  }
`;

const WrapperRecomment = styled.div`
  margin-top: 60px;
  text-align: center;

  > .title {
    font-family: "kelson_sansbold";
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
  }

  > .desc {
    margin: 16px auto 0;
    max-width: 656px;
    font-size: 18px;
  }

  > .research-list {
    display: flex;
    margin: 82px 54px 0 60px;
    align-items: baseline;
    justify-content: space-between;
    .list {
      text-align: center;

      img {
        width: 120px;
      }

      .label {
        margin-top: 36px;
        font-size: 24px;
        font-family: "kelson_sansbold";
        text-transform: uppercase;
      }

      .desc {
        margin-top: 4px;
        font-size: 18px;
      }
    }
  }

  > button {
    margin-top: 64px;
    width: 320px;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  margin: 0 0px;

  .left {
    flex: 1;
    text-align: center;
    margin: 25px 31px;
    font-family: "kelson_sansbold";
    font-size: 60px;
    line-height: 70px;
    text-transform: uppercase;
  }

  .right {
    flex: 1;
    font-size: 18px;
    margin: 25px 20px;
  }
`;

const DivImg = styled.div`
  padding: 25px;
  .container-img {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;
    grid-gap: 10px;

    .title-img {
      background-color: black;
      margin: 10px 0;
      padding: 10px;
      color: white;
      font-size: 14px;
      text-align: center;
    }

    .item-img {
      img {
        width: 100%;
        border: 3px solid black;
      }
    }
  }

  .title-header {
    font-size: 52px;
    font-weight: bold;
  }

  .title-img {
    background-color: black;
    margin: 10px 0;
    padding: 10px;
    color: white;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  .pagebreak {
    page-break-before: always;
  }
`;

const WrapperContentDISC = styled.div`
  display: flex;
  margin-top: 40px;
  .left {
    flex: 1;
    font-size: 20px;
  }

  .right {
    flex: 1;
    font-size: 20px;
    margin-left: 60px;
  }

  .box-container {
    text-align: center;
    background-color: #f6f6f6;
    height: 680px;
    width: 470px;
    padding: 70px 20px;

    .box-header,
    .box-type {
      font-weight: bold;
      font-size: 32px;
      margin: 20px;
    }

    .box-type {
      color: #083aa9;
      margin-bottom: 20px;
    }

    img {
      width: 180px;
      height: 180px;
      margin-bottom: 10px;
    }
  }

  .nature-container {
    .nature-title {
      font-weight: bold;
      font-size: 28px;
    }
  }
`;

const Report = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { uid, userId, reportConfId } = useParams();
  const { report, quickSummary, discSummary } = useSelector(
    (state) => state.report
  );
  const [discData, setDiscData] = useState(discSummary);

  useEffect(() => {
    dispatch(setUIdUserIdAndReportConfId(uid, userId, reportConfId));
    dispatch(getRecommendById(uid, userId));
    dispatch(getQuickSummaryById(uid, userId, reportConfId));
    dispatch(getDiscSummaryById(uid, userId, reportConfId));
  }, []);

  useEffect(() => {
    console.log("discSummary", discSummary);
    if (discSummary != null) {
      setDiscData(discSummary);
      console.log("discData", discData);
      console.log("discData", discData?.disc_result?.length);
    }
  }, [discSummary]);

  const DisplayResult = (result) => {
    if (result === "ดี") {
      return <div className="information green">{result}</div>;
    } else if (result === "ปานกลาง") {
      return <div className="information yellow">{result}</div>;
    }
    return <div className="information red">{result}</div>;
  };

  return (
    <div ref={ref}>
      <Div>
        <div className="test-detail">
          <div className="profile-name">{report?.first_name}</div>
          <div className="profile-name">{report?.last_name}</div>
          <div className="test-name"> {report?.campaign_name}</div>
          <div className="test-summary">
            {report?.subject?.map((it) => {
              return (
                <div>
                  {it?.title} {it?.questions} ข้อ{" "}
                </div>
              );
            })}
          </div>
          <div className="test-kit">
            <div className="kit-name">Personal information</div>
            <div className="kit-data">
              <div>Email : {report?.email}</div>
              <div>Status : {report?.use_in}</div>
              <div>Start Time: {report?.start_time}</div>
              <div>Leave Time: {report?.end_time}</div>
              <div>Time in Session (minutes): {report?.used_time}</div>
              <div>Report Generate: {report?.generate_at}</div>
            </div>
          </div>
        </div>
      </Div>

      <DivImg>
        <div className="pagebreak">
          <div className="infomation-test-img">
            <div className="title-header">ภาพขณะทำการทดสอบ</div>
            <div className="container-img">
              <div className="item-img">
                <div className="title-img">
                  Screenshot#1 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot1?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot1?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#2 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot2?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot2?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#3 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot3?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot3?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#4 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot4?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot4?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#5 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot5?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot5?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#6 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot6?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot6?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#7 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot7?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot7?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#8 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot8?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot8?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#9 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot9?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot9?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#10 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot10?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot10?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#11 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot11?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot11?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#12 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot12?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot12?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#13 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot13?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot13?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#14 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot14?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot14?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#15 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot15?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot15?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
              <div className="item-img">
                <div className="title-img">
                  Screenshot#16 - ช่วงเริ่มทำ{" "}
                  {formatValue(
                    "DateTime2",
                    report?.screenshot16?.snapshot_time_created
                  )}
                </div>
                <div className="img-box">
                  <img src={report?.screenshot16?.snapshot_url || defaultImg} />
                  <div className="overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DivImg>
      <DivQuickSummary>
        <div className="pagebreak">
          <Banner />
          <WrapperQuickSummary>
            {/* <div className="summary-wrapper">
              <div className="left">
                <div className="sub-title">Report</div>
                <div className="title">Quick </div>
                <div className="title"> Summary</div>
                <div className="desc">
                  ผลการทดสอบของคุณ{" "}
                  {`${quickSummary?.first_name} ${quickSummary?.last_name}`}
                  <br />
                  ด้านทักษะ ความรู้ และบุคลิกภาพ
                </div>
              </div>
              <div className="right">
                <div className="row">
                  <div className="category">วิชา</div>
                  <div className="amount">คะแนน (คิดเป็น %)</div>
                </div>
                {quickSummary?.exam_result_summary?.map((it) => {
                  if (it?.subject_title === "DISC") {
                    return (
                      <div className="row">
                        <div className="category">
                          <CategoryLink to="#sleep">
                            {it?.subject_title}
                          </CategoryLink>
                        </div>
                        <div className="amount">{`${it?.result} ${it?.result_desc}`}</div>
                      </div>
                    );
                  }
                  return (
                    <div className="row">
                      <div className="category">
                        <CategoryLink to="#sleep">
                          {it?.subject_title}
                        </CategoryLink>
                      </div>
                      <div className="amount">{it?.result} %</div>
                    </div>
                  );
                })}
              </div>
            </div> */}
            <div className="summary-wrapper">
              <div className="left">
                <div className="sub-title">Report</div>
                <div className="title">Quick </div>
                <div className="title"> Summary</div>
                <div className="desc">
                  ผลการทดสอบของคุณ{" "}
                  {`${quickSummary?.first_name || ""} ${
                    quickSummary?.last_name || "-"
                  }`}
                  <br />
                  ด้านทักษะ ความรู้ และบุคลิกภาพ
                </div>
              </div>
              <div className="right">
                <div className="row">
                  <div className="category" style={{ width: "165px" }}>
                    วิชา
                  </div>
                  <div className="category">คะแนน</div>
                  <div className="amount" style={{ width: "100%" }}>
                    คะแนน (คิดเป็น %)
                  </div>
                </div>
                {quickSummary?.exam_result_summary?.map((it) => {
                  if (it?.subject_title === "DISC") {
                    return (
                      <div className="row">
                        <div className="category" style={{ width: "165px" }}>
                          <CategoryLink to="#sleep">
                            {it?.subject_title}
                          </CategoryLink>
                        </div>
                        <div className="category" style={{ width: "100%" }}>
                          {it?.score}/{it?.full_score}
                        </div>
                        <div
                          className="amount"
                          style={{ width: "100%" }}
                        >{`${it?.result} ${it?.result_desc}`}</div>
                      </div>
                    );
                  }
                  return (
                    <div className="row">
                      <div className="category" style={{ width: "165px" }}>
                        <CategoryLink to="#sleep">
                          {it?.subject_title}
                        </CategoryLink>
                      </div>
                      <div className="category">
                        {it?.score}/{it?.full_score}
                      </div>
                      <div className="amount" style={{ width: "100%" }}>
                        {it?.result} %
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* {quickSummary?.exam_result_detail?.map((it) => {
              return (
                <div className="cetegory-wrapper">
                  <div id={it?.subject_title} className="cetegory">
                    <div className="left">
                      <div className="title">{it?.subject_title}</div>
                      <a
                        className="btn-full-report"
                        href={`/full-report/${it?.subject_title}`}
                        target="_blank"
                      >
                        วิเคราะห์คะแนนรายวิชา
                      </a>
                    </div>
                    <div className="right">
                      {DisplayResult(it?.lable)}

                      <div className="detail">
                        <p>{it?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })} */}
          </WrapperQuickSummary>
          <div className="pagebreak">
            <WrapperPositionPercentile>
              <div className="section-bottom">
                <div className="section-wrapper">
                  <div className="left">
                    ตำแหน่ง <br /> PERCENTILE <br /> ในรอบการทดสอบ
                  </div>
                  <div className="right">
                    <div>
                      <p className="content">
                        ตำแหน่ง PERCENTILE <br />
                        ของคุณ
                        {`${quickSummary?.first_name} ${quickSummary?.last_name}`}
                        <br />
                        อยู่ลำดับที่ {quickSummary?.percenttile_order}TH
                      </p>
                      <span className="sub-content">
                        จากผู้สอบทั้งหมด {quickSummary?.total_examer} คน
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </WrapperPositionPercentile>
            <WrapperGraph>
              <div className="chart">
                {
                  <div className="chart-container">
                    <div className="chart-box">
                      <BarChart
                        dataLabel={quickSummary?.exam_result_set}
                        dataResult={quickSummary?.exam_user_result}
                      />
                    </div>
                  </div>
                }
                <p>
                  มี {quickSummary?.count_result_less} คนที่ได้คะแนนน้อยกว่าคุณ
                  &nbsp;&nbsp;&nbsp;&nbsp;มี {quickSummary?.count_result_more}{" "}
                  คนที่ได้คะแนนมากกว่าคุณ
                </p>
              </div>
            </WrapperGraph>
          </div>
        </div>
      </DivQuickSummary>
      <DivDISC>
        <div className="pagebreak">
          { !discData?.disc_result?.length ? <DISCBanner /> : ""}
          { discData?.disc_result?.length && (
            <div className="disc-container" style={{ padding: "45px" }}>
              <h1>การแปลผลแบบวัดบุคลิกภาพ</h1>
              <h1>DISC</h1>
              <p>
                DISC
                คือลักษณะบุคลิกภาพของคนแต่ละประเภทที่สังเกตได้จากนิสัยการทำงานและสภาพแวดล้อมของคนแต่ละประเภท
                จะประกอบไปด้วย 4 ลักษณะ คือ Dominance, Influence, Steadiness และ
                Compliance ซึ่งจริง ๆ แล้วทุกคนอาจมีหลายลักษณะอยู่ในตัวเองก็ได้
                หากยิ่งมีหลายแบบก็ยิ่งหมายถึงความสามารถในการปรับตัวเพื่อใช้ในสถานการณ์ต่างๆได้
                (แต่ต้องใช้ถูกบริบทด้วย)
              </p>
              <p>
                อย่างไรก็ตาม คนที่ได้ผลเป็นอย่างหนึ่ง
                ไม่ได้หมายความว่าบุคคลนั้นจะเป็นแบบนั้นตลอดไป
                ทุกคนสามารถเปลี่ยนแปลงได้ตามสถาพแวดล้อมการทำงาน หรือสิ่งรอบข้าง
                เช่น คนที่ได้ Steadiness ตามบุคลิกภาพ จะไม่ค่อยชอบออกความเห็น
                แต่เมื่อถึงเวลาเขาก็สามารถเป็นผู้นำได้ เหมือ Mr. Satya Nadella
                ซึ่งเป็นคนบุคลิกแบบ Steadiness แต่เป็น CEO ของ Microsoft
              </p>

              <h1>วิธีการอ่านผล</h1>
              <ul>
                <li>
                  หากผู้สอบ ได้ผล 1 ตัว ไม่ว่าจะเป็น D, I, S, หรือ C
                  ให้อ่านผลตามตัวอักษร
                </li>
                <li>
                  หากผู้สอบ ได้ผล มากกว่า 1 ตัว เช่น DI, DS, DC, IS, IC, SC,
                  DIS, DIC, ISC, DISC ใหัอ่านผลของทั้งของตัวอักษร
                  เพราะผู้สอบมีบุคลิกผสม
                  เละเขาสามารถใช้หลายบุคลิกตามแต่สถานการณ์
                </li>
              </ul>

              {discData?.disc_result?.map((it) => (
                <WrapperContentDISC>
                  <div className="left">
                    <div className="box-container">
                      <div className="box-header">Your DISC Result</div>
                      <img src={it?.image_url} />
                      <div className="box-type">{it?.name.toUpperCase()}</div>
                      <div className="box-detail">{it?.result}</div>
                    </div>
                  </div>
                  <div className="right">
                    <div
                      dangerouslySetInnerHTML={{ __html: it?.description }}
                    ></div>
                  </div>
                </WrapperContentDISC>
              ))}
            </div>
          )}

          {/* <WrapperContent>
            <div className="left">
              {discSummary?.disc_result}
              <br />
              {discSummary?.disc_character}
            </div>
          </WrapperContent>
          <div className="content">{discSummary?.disc_description_full}</div> */}
        </div>
      </DivDISC>
    </div>
  );
});

export default Report;
