import styled from "styled-components";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import ReportLayout from "../../components/layouts/ReportLayout";
import NextButton from "../../components/common/button/NextButton";

import imageBanner from "../../assets/images/report/banner-promise.png";
import icon1 from "../../assets/images/report/icon-promise1.svg";
import icon2 from "../../assets/images/report/icon-promise2.svg";
import icon3 from "../../assets/images/report/icon-promise3.svg";
import icon4 from "../../assets/images/report/icon-promise4.svg";
import icon5 from "../../assets/images/report/icon-promise5.svg";

import { useDispatch, useSelector } from "react-redux";
import { getDiscSummaryById, getRecommendById } from "redux/actions/report";

import ComplianceImg from "../../assets/images/report/Compliance_img.jpg";
import DominanceImg from "../../assets/images/report/Dominance_img.jpg";
import InfluenceImg from "../../assets/images/report/Influence_img.jpg";
import SteadinessImg from "../../assets/images/report/Steadiness_img.jpg";

const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  height: 520px;
  background: url("${imageBanner}") no-repeat center center;
  background-size: cover;
  text-align: center;

  .title {
    font-family: "kelson_sansbold";
    text-transform: uppercase;
    font-size: 32px;
  }

  .title2 {
    font-family: "kelson_sansbold";
    text-transform: uppercase;
    font-size: 48px;
  }

  .desc {
    margin: 12px auto 0;
    max-width: 700px;
    font-size: 20px;
  }
`;

const WrapperContent = styled.div`
  display: flex;

  margin: 50px -64px;

  .left {
    flex: 1;
    font-size: 20px;
    margin: 0 64px;
  }

  .right {
    flex: 1;
    font-size: 20px;
    margin: 0 64px;
  }

  .box-container {
    text-align: center;
    background-color: #f6f6f6;
    height: 720px;
    width: 600px;
    padding: 100px 20px;

    .box-header,
    .box-type {
      font-weight: bold;
      font-size: 32px;
      margin: 20px;
    }

    .box-type {
      color: #083aa9;
      margin-bottom: 20px;
    }

    img {
      width: 180px;
      height: 180px;
      margin-bottom: 10px;
    }
  }

  .nature-container {
    .nature-title {
      font-weight: bold;
      font-size: 28px;
    }
  }
`;

const Banner = () => {
  const { discSummary, uid, userId } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const [discData, setDiscData] = useState(discSummary);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("USER_DATA"))
  );

  useEffect(() => {
    dispatch(
      getDiscSummaryById(uid ?? userData.uid, userId ?? userData.userId)
    );
    dispatch(getRecommendById(uid ?? userData.uid, userId ?? userData.userId));
  }, []);

  useEffect(() => {
    setDiscData(discSummary);
  }, [discSummary]);

  return (
    <StyledBanner>
      {/* <div className="title">disc result</div>
      <div className="title2">
        คุณมีบุคลิกแบบ {discData?.disc_result} {discData?.disc_character}
      </div> */}
      {!discData?.disc_result && (
        <div className="title2">
          ไม่มีผล DISC Result <br />
          เนื่องจากไม่มีในรอบสอบนี้
        </div>
      )}
      <div className="desc">{discData?.disc_description_medium}</div>
    </StyledBanner>
  );
};

const DISC = () => {
  const { discSummary, uid, userId, reportConfId } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();
  const [discData, setDiscData] = useState(discSummary);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("USER_DATA"))
  );

  useEffect(() => {
    dispatch(
      getDiscSummaryById(
        uid ?? userData.uid,
        userId ?? userData.userId,
        reportConfId ?? userData.reportConfId
      )
    );
  }, []);

  useEffect(() => {
    console.log("discSummary", discSummary);
    if (discSummary != null) {
      setDiscData(discSummary);
      console.log(" 163", discData);
    }
  }, [discSummary]);

  return (
    <ReportLayout header={discData == null ? <Banner /> : ""}>
      {/* <WrapperContent> */}
      {/* <div className="left">
          {discData?.disc_result}
          <br />
          {discData?.disc_character}
        </div>
        <div className="right">
          <div className="content">{discData?.disc_description_full}</div>
        </div> */}

      {discData != null && (
        <div className="disc-container">
          <h1>การแปลผลแบบวัดบุคลิกภาพ</h1>
          <h1>DISC</h1>
          <p>
            DISC
            คือลักษณะบุคลิกภาพของคนแต่ละประเภทที่สังเกตได้จากนิสัยการทำงานและสภาพแวดล้อมของคนแต่ละประเภท
            จะประกอบไปด้วย 4 ลักษณะ คือ Dominance, Influence, Steadiness และ
            Compliance ซึ่งจริง ๆ แล้วทุกคนอาจมีหลายลักษณะอยู่ในตัวเองก็ได้
            หากยิ่งมีหลายแบบก็ยิ่งหมายถึงความสามารถในการปรับตัวเพื่อใช้ในสถานการณ์ต่างๆได้
            (แต่ต้องใช้ถูกบริบทด้วย)
          </p>
          <p>
            อย่างไรก็ตาม คนที่ได้ผลเป็นอย่างหนึ่ง
            ไม่ได้หมายความว่าบุคคลนั้นจะเป็นแบบนั้นตลอดไป
            ทุกคนสามารถเปลี่ยนแปลงได้ตามสถาพแวดล้อมการทำงาน หรือสิ่งรอบข้าง เช่น
            คนที่ได้ Steadiness ตามบุคลิกภาพ จะไม่ค่อยชอบออกความเห็น
            แต่เมื่อถึงเวลาเขาก็สามารถเป็นผู้นำได้ เหมือ Mr. Satya Nadella
            ซึ่งเป็นคนบุคลิกแบบ Steadiness แต่เป็น CEO ของ Microsoft
          </p>

          <h1>วิธีการอ่านผล</h1>
          <ul>
            <li>
              หากผู้สอบ ได้ผล 1 ตัว ไม่ว่าจะเป็น D, I, S, หรือ C
              ให้อ่านผลตามตัวอักษร
            </li>
            <li>
              หากผู้สอบ ได้ผล มากกว่า 1 ตัว เช่น DI, DS, DC, IS, IC, SC, DIS,
              DIC, ISC, DISC ใหัอ่านผลของทั้งของตัวอักษร เพราะผู้สอบมีบุคลิกผสม
              เละเขาสามารถใช้หลายบุคลิกตามแต่สถานการณ์
            </li>
          </ul>

          {discData?.disc_result?.length > 0 && discData?.disc_result?.map((it) => (
            <WrapperContent>
              <div className="left">
                <div className="box-container">
                  <div className="box-header">Your DISC Result</div>
                  <img src={it?.image_url} />
                  <div className="box-type">{it?.name.toUpperCase()}</div>
                  <div className="box-detail">{it?.result}</div>
                </div>
              </div>
              <div className="right">
                <div
                  dangerouslySetInnerHTML={{ __html: it?.description }}
                ></div>
              </div>
            </WrapperContent>
          ))}
        </div>
      )}

      {/* </WrapperContent> */}
    </ReportLayout>
  );
};

export default DISC;
