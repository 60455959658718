import styled from 'styled-components'

import Header from './components/Header'
import Footer from './components/Footer'

const Div = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main-content {
    flex: 1;
    border-bottom: 1px solid var(--line);
  }
`

const MainLayout = ({ children, ...props }) => {
  return (
    <Div {...props}>
      <Header />
      <div className="main-content">{children}</div>
      <Footer />
    </Div>
  )
}

export default MainLayout
