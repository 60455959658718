import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import ReportLayout from '../../components/layouts/ReportLayout'
import NextButton from '../../components/common/button/NextButton'

import imageBanner from '../../assets/images/report/banner-our-body.png'

const Div = styled.div`
  display: flex;
  margin: 0 -64px;

  .left {
    flex: 1;
    margin: 0 64px;

    button {
      width: 100%;
      margin-top: 62px;
    }
  }

  .right {
    flex: 1;
    margin: 0 64px;
    font-family: 'kelson_sansbold';
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
  }
`

const StyledBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 720px;
  background: url('${imageBanner}') no-repeat center center;
  background-size: cover;
  color: var(--off-white);

  .title {
    margin-top: -210px;
    margin-right: 70px;
    font-family: 'kelson_sansbold';
    text-transform: uppercase;
    font-size: 56px;
    line-height: 64px;
    max-width: 558px;
  }

  .desc {
    margin-top: 80px;
    max-width: 320px;
  }
`

const Banner = () => {
  return (
    <StyledBanner>
      <div className="title">
        Human:
        <br />
        The world within
      </div>
      <div className="desc">
        Take a deep-dive into the universe that’s inside each and every one of us, by exploring a
        shared biology that we often don't take the time to appreciate, or understand. Heart, brain,
        eyes, blood, tears; "Human" uncovers not only the science behind how our bodies work, but
        how what's inside powers every moment of what we do out in the world. Personal profiles of
        people from around the globe become entry points into deeper stories about how the body's
        many systems function.
      </div>
    </StyledBanner>
  )
}

const ReportOurHumanBody = () => {
  const history = useHistory()
  return (
    <ReportLayout header={<Banner />}>
      <Div>
        <div className="left">
          <div className="content">
            <p>
              It’s a balancing act; producing a documentary that appeals to everyone is a skill.
              Human: The World Within manages to walk the line between educating and entertaining,
              borrowing from previous success stories and using their techniques to keep us engaged.
            </p>
            <p>
              The first episode of the six episodes is entitled “React”, and explores the nervous
              system, its relationship with the brain, and tries to explain the complex nature of
              why we react in certain ways, how we can train ourselves to respond appropriately, and
              how our brain sends information, almost digitally, through our spines to every point
              in our bodies.
            </p>
            <p>
              It’s a balancing act; producing a documentary that appeals to everyone is a skill.
              Human: The World Within manages to walk the line between educating and entertaining,
              borrowing from previous success stories and using their techniques to keep us engaged.
            </p>
            <p>
              The first episode of the six episodes is entitled “React”, and explores the nervous
              system, its relationship with the brain, and tries to explain the complex nature of
              why we react in certain ways, how we can train ourselves to respond appropriately, and
              how our brain sends information, almost digitally, through our spines to every point
              in our bodies.
            </p>
          </div>
          <NextButton onClick={() => history.push('/report/discover-yourself')}>
            Understand our DNA
          </NextButton>
        </div>
        <div className="right">The first episode of the six episodes is entitled “React”</div>
      </Div>
    </ReportLayout>
  )
}

export default ReportOurHumanBody
