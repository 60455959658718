const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
});

export const CLEAR_ALERT = "CLEAR_ALERT";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const LOGIN = genRequest("LOGIN");

export const GET_EXAM_USER_BY_ID = genRequest("GET_EXAM_USER_BY_ID");
export const GET_QUICK_BY_ID = genRequest("GET_QUICK_BY_ID");
export const GET_DISC_BY_ID = genRequest("GET_DISC_BY_ID");

export const SET_UID_AND_USERID = "SET_UID_AND_USERID"

