import styled from 'styled-components'

import MainLayout from './MainLayout'
import ReportMenu from './components/ReportMenu'

const Div = styled.div`
  .report-menu-wrapper {
    width: 100%;
    border-top: 1px solid var(--line);
    border-bottom: 1px solid var(--line);
  }

  .report-content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    .report-body {
      padding: 80px;
    }
  }
`

const ReportLayout = ({ header, children, ...props }) => {
  return (
    <MainLayout {...props}>
      <Div>
        <div className="report-menu-wrapper">
          <ReportMenu />
        </div>
        <div className="report-content">
          {header && <div className="report-header">{header}</div>}
          {children && <div className="report-body">{children}</div>}
        </div>
      </Div>
    </MainLayout>
  )
}

export default ReportLayout
