import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ReportLayout from '../../components/layouts/ReportLayout'

import imageChart from '../../assets/images/report/chart.png'

const Div = styled.div`
  .summary-wrapper {
    display: flex;
    margin: 0 -64px;

    .left {
      flex: 1;
      margin: 0 64px;

      .sub-title {
        font-family: 'kelson_sansbold';
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
      }

      .title {
        margin-top: 8px;
        font-family: 'kelson_sansbold';
        font-size: 56px;
        line-height: 64px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .desc {
        margin-top: 16px;
        font-size: 14px;
      }
    }

    .right {
      flex: 1;
      margin: 0 64px;

      .row {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        border-top: 1px solid var(--off-black);

        &:first-child {
          border-top: none;
        }
      }

      a {
        color: var(--off-black);
        text-decoration: none;

        svg {
          margin-right: 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .chart-wrapper {
    margin-top: 67px;

    .information-wrapper {
      display: flex;
      margin-top: 24px;

      .information {
        position: relative;
        flex: 1;
        padding-left: 32px;
        padding-right: 12px;

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top: 0;
          left: 0;
        }
      }
    }

    .chart {
      margin-top: 32px;

      img {
        width: 100%;
      }
    }
  }

  .cetegory-wrapper {
    margin-top: 105px;

    .cetegory {
      display: flex;
      margin: 0 -8px;

      .left {
        flex: 1;
        margin: 0 8px 64px;
      }

      .right {
        flex: 1;
        margin: 0 8px 64px;
      }

      .title {
        margin-bottom: 24px;
        font-family: 'kelson_sansbold';
        font-size: 48px;
        line-height: 56px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .btn-full-report {
        padding: 8px 24px;
        font-family: 'kelson_sansbold';
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--off-black);
        border: 1px solid var(--off-black);
        text-decoration: none;
      }

      .information {
        position: relative;
        font-size: 18px;
        padding: 9px 8px 9px 32px;
        border-top: 1px solid var(--off-black);

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top: 15px;
          left: 6px;
        }
      }

      .detail {
        margin-top: 24px;
      }
    }
  }
`

const CategoryLink = ({ children, ...props }) => {
  return (
    <HashLink {...props}>
      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
      {children}
    </HashLink>
  )
}

const ReportSnapshotHealth = () => {
  return (
    <ReportLayout>
      <Div>
        <div className="summary-wrapper">
          <div className="left">
            <div className="sub-title">Report</div>
            <div className="title">Quick Summary</div>
            <div className="desc">
              บทสรุปการตรวจสอบ ‘พื้นฐานสุขภาพ และศักยภาพของร่างกาย’ ที่ซ่อนอยู่ใน DNA ของคุณ
              Chavalit Chanyaruksul (Sample ID : 1234567890) จํานวน 209 รายการ แบ่งเป็น 10 หมวด
            </div>
          </div>
          <div className="right">
            <div className="row">
              <div className="category">หมวดหมู่</div>
              <div className="amount">จำนวนรายการตรวจ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#sleep">Sleep & Stress</CategoryLink>
              </div>
              <div className="amount">6 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#work">Work Performance</CategoryLink>
              </div>
              <div className="amount">7 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#sport">Sport & Fitness</CategoryLink>
              </div>
              <div className="amount">6 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#health">Health</CategoryLink>
              </div>
              <div className="amount">72 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#cancer">Cancer Risk</CategoryLink>
              </div>
              <div className="amount">29 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#genetic">Genetic Disorder</CategoryLink>
              </div>
              <div className="amount">3 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#origin">Origin & Fun Facts</CategoryLink>
              </div>
              <div className="amount">27 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#beauty">Beauty & Skin</CategoryLink>
              </div>
              <div className="amount">12 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#nutrition">Nutrition</CategoryLink>
              </div>
              <div className="amount">13 รายการ</div>
            </div>
            <div className="row">
              <div className="category">
                <CategoryLink to="#drug">Drug Response</CategoryLink>
              </div>
              <div className="amount">34 รายการ</div>
            </div>
          </div>
        </div>
        <div className="chart-wrapper">
          <div className="title">
            เพื่อให้คุณเข้าใจตัวเองได้อย่างรวดเร็ว ว่ามีความเสี่ยงต่อปัญหาสุขภาพต่างๆ มากน้อยอย่างไร
            หรือมีศักยภาพใดบ้างที่โดดเด่น FUSION ได้จัดระดับความสําคัญ พร้อมแสดงสัญลักษณ์ไว้ดังนี้
          </div>
          <div className="information-wrapper">
            <div className="information red">
              สัญลักษณ์สีแดง คือ “ควรให้ความใส่ใจเป็นพิเศษ” อาจเป็นความเสี่ยงสุขภาพที่มากกว่า
              หรือประสิทธิภาพของร่างกายที่ต่ํากว่า ค่าเฉลี่ยคนทั่วไป
            </div>
            <div className="information yellow">
              สัญลักษณ์สีเหลือง คือ “ผลลัพธ์ความเสี่ยงสุขภาพ” หรือประสิทธิภาพของร่างกาย
              อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
            </div>
            <div className="information green">
              สัญลักษณ์สีเขียว คือ “ดีกว่าค่าเฉลี่ยคนทั่วไป” อาจเป็นความเสี่ยงสุขภาพที่ต่ํากว่าหรือ
              เป็นประสิทธิภาพที่สูงกว่าค่าเฉลี่ยคนทั่วไป
            </div>
          </div>
          <div className="chart">
            <img src={imageChart} />
          </div>
        </div>
        <div className="cetegory-wrapper">
          <div id="sleep" className="cetegory">
            <div className="left">
              <div className="title">Sleep & Stress</div>
              <a className="btn-full-report" href="/full-report/sleep" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="work" className="cetegory">
            <div className="left">
              <div className="title">Work Performance</div>
              <a className="btn-full-report" href="/full-report/work" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="sport" className="cetegory">
            <div className="left">
              <div className="title">Sport & Fitness</div>
              <a className="btn-full-report" href="/full-report/sport" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="health" className="cetegory">
            <div className="left">
              <div className="title">Health</div>
              <a className="btn-full-report" href="/full-report/health" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="cancer" className="cetegory">
            <div className="left">
              <div className="title">Cancer Risk</div>
              <a className="btn-full-report" href="/full-report/cancer" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="genetic" className="cetegory">
            <div className="left">
              <div className="title">Genetic Disorder </div>
              <a className="btn-full-report" href="/full-report/genetic" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="origin" className="cetegory">
            <div className="left">
              <div className="title">Origin & Fun Facts</div>
              <a className="btn-full-report" href="/full-report/origin" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="beauty" className="cetegory">
            <div className="left">
              <div className="title">Beauty & Skin</div>
              <a className="btn-full-report" href="/full-report/beauty" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="nutrition" className="cetegory">
            <div className="left">
              <div className="title">Nutrition</div>
              <a className="btn-full-report" href="/full-report/nutrition" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
          <div id="drug" className="cetegory">
            <div className="left">
              <div className="title">Drug Response</div>
              <a className="btn-full-report" href="/full-report/drug" target="_blank">
                View full report
              </a>
            </div>
            <div className="right">
              <div className="information green">
                พบ 6 หัวข้อที่อยู่ในเกณฑ์ดีกว่าค่าเฉลี่ยคนทั่วไป
              </div>
              <div className="information yellow">
                พบ 6 หัวข้อที่อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
              </div>
              <div className="information red">พบ 3 หัวข้อที่ควรใส่ใจเป็นพิเศษ คือ</div>
              <div className="detail">
                <p>
                  1 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  2 ความสามารถในการซ่อมแซมผิวขาว 
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
                <p>
                  3 ความสามารถในการซ่อมแซมผิวขาว
                  <br />
                  ผลลัพธ์ : ต่ํากว่าค่าเฉลี่ยคนทั่วไป
                  <br />
                  คําแนะนํา : ควรเลี่ยงหรือลดการอยู่กับแสงแดดนานๆ และทาครีมที่มี SPF 50 ทุก 2
                  ชั่วโมง
                </p>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportSnapshotHealth
