import styled from 'styled-components'
import { Link } from 'react-router-dom'

import imgLogo from '../../../assets/images/logo-black.png'
import iconNoti from '../../../assets/images/icon-noti.svg'
import iconBag from '../../../assets/images/icon-bag.svg'

const Div = styled.header`
  width: 100%;
  max-width: 1440px;
  padding: 24px 56px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 120px;
  }

  .main-menu {
    display: flex;
    align-items: center;

    > a {
      font-family: 'kelson_sansbold';
      font-size: 16px;
      margin-left: 20px;
      color: var(--off-black);
      text-decoration: none;
      text-transform: uppercase;

      &.menu-noti,
      &.menu-bag {
        margin-left: 26px;

        > img {
          display: block;
          width: 18px;
        }
      }

      &.menu-profile {
        display: block;
        min-width: 32px;
        height: 32px;
        padding: 4px;
        margin-left: 26px;
        text-align: center;
        color: var(--disabled);
        background-color: var(--text-body);
        border-radius: 50%;
        text-decoration: none !important;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Header = () => {
  return (
    <Div>
      <img className="logo" src={imgLogo} />
      <div className="main-menu">
        <Link className="menu-product" to="#">
          Products
        </Link>
        <Link className="menu-register" to="#">
          Register test kits
        </Link>
        <Link className="menu-our-team" to="#">
          Our team
        </Link>
        <Link className="menu-blog" to="#">
          Blog
        </Link>
        <Link className="menu-noti" to="#">
          <img src={iconNoti} />
        </Link>
        <Link className="menu-bag" to="#">
          <img src={iconBag} />
        </Link>
        <Link className="menu-profile" to="#">
          N
        </Link>
      </div>
    </Div>
  )
}

export default Header
