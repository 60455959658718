import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const customBorder = {
  id: "customBorder",
  afterDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      chartArea: { top, right, bottom, left, width, height },
    } = chart;
    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.moveTo(left, top);
    ctx.lineTo(right - 2, top);
    ctx.lineTo(right - 2, bottom);
    ctx.lineTo(left, bottom);
    ctx.lineTo(left, top);
    ctx.stroke();
  },
};

const customTitleTop = {
  id: "customTitleTop",
  afterDraw: (chart) => {
    const { ctx } = chart;
    ctx.save();
    ctx.textAlign = "center";
    ctx.font = "bold 26px Arial";
    ctx.fillText("Distribution of Candidates Scores", chart.width / 2, 45);
    ctx.restore();
  },
};

ChartJS.register(customBorder);
ChartJS.register(customTitleTop);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Distribution of Candidates Scores",
    },
  },
  scales: {
    y: {
      beingAtZero: false,
      drawBorder: true,
      ticks: {
        display: false,
        color: "black",
      },

      title: {
        display: true,
        text: "# of Candidates",
        font: {
          size: 24,
          weight: "bold",
        },
        color: "black",
      },
      position: "left",
      grid: {
        display: false,
      },
    },
    x: {
      offset: true,
      beingAtZero: false,
      drawBorder: true,
      title: {
        display: true,
        text: "Raw Score",
        font: {
          size: 24,
          weight: "bold",
        },
        color: "black",
      },
      position: "bottom",
      grid: {
        display: false,
      },
    },
  },
};
const config = {
  options: options,
};

const BarChart = ({ dataLabel, dataResult }) => {
  const dataArray = dataLabel;
  const labels = dataLabel;
  const data = {
    labels,
    datasets: [
      {
        label: "Exam User Result",
        data: dataArray,
        backgroundColor: labels?.map((it) => {
          if (it === dataResult) return "#243A73";
          return "#D1D1D1";
        }),
      },
    ],
  };

  return (
    <div className="container">
      <div className="chart-container">
        <div>
          <Bar options={config.options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default BarChart;
