import {
  GET_EXAM_USER_BY_ID,
  GET_QUICK_BY_ID,
  GET_DISC_BY_ID,
  SET_UID_AND_USERID,
} from "redux/actionTypes";

const initialState = {
  total: 0,
  limit: 20,
  skip: 0,
  items: [],
  report: {},
  quickSummary: {},
  discSummary: null,
  uid: null,
  userId: null,
  reportConfId: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_UID_AND_USERID: {
      const { uid, userId, reportConfId } = action.payload;
      return {
        ...state,
        uid: uid,
        userId: userId,
        reportConfId: reportConfId,
      };
    }
    case GET_EXAM_USER_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_EXAM_USER_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_EXAM_USER_BY_ID.SUCCESS: {
      return {
        ...state,
        report: action.payload,
      };
    }
    case GET_QUICK_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_QUICK_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_QUICK_BY_ID.SUCCESS: {
      return {
        ...state,
        quickSummary: action.payload,
      };
    }
    case GET_DISC_BY_ID.REQUEST:
      return {
        ...state,
      };
    case GET_DISC_BY_ID.FAILURE:
      return {
        ...state,
      };
    case GET_DISC_BY_ID.SUCCESS: {
      return {
        ...state,
        discSummary: action.payload,
      };
    }

    default:
      return state;
  }
};
