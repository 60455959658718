import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import {} from "redux/actions/report";

const Div = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  justify-content: baseline;

  a {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 80px;
    padding: 12px;
    font-family: "kelson_sansbold";
    font-size: 18px;
    color: var(--off-black);
    text-decoration: none;
    text-transform: uppercase;
    border-left: 1px solid var(--line);

    &.active {
      color: var(--off-white);
      background-color: var(--off-black);
    }

    &:last-child {
      border-right: 1px solid var(--line);
    }
  }
`;

const ReportMenu = () => {
  const dispatch = useDispatch();
  const { report, uid, userId, reportConfId } = useSelector(
    (state) => state.report
  );
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));

  useEffect(() => {}, []);
  return (
    <Div>
      <NavLink
        to={`/report/${uid ?? userData?.uid}/${userId ?? userData?.userId}/${
          reportConfId ?? userData?.reportConfId
        }`}
        activeClassName="active"
        exact
      >
        {report?.first_name}
      </NavLink>

      <NavLink to="/report/summary" activeClassName="active">
        QUICK SUMMARY
      </NavLink>
      <NavLink to="/report/disc" activeClassName="active">
        DISC
      </NavLink>
      {/* <NavLink to="/report/recommendataion" activeClassName="active">
        RECOMMENDATION
      </NavLink> */}
      {/* <NavLink to="/report/learn-about-fushion" activeClassName="active">
        Learn more About Fushion
      </NavLink>
      <NavLink to="/report/our-human-body" activeClassName="active">
        Our human body
      </NavLink>
      <NavLink to="/report/discover-yourself" activeClassName="active">
        Discover Yourself
      </NavLink>

      <NavLink to="/report/snapshot-your-health" activeClassName="active">
        Snapshot your health
      </NavLink>
      <NavLink to="/report/special-consult" activeClassName="active">
        Special consult for you
      </NavLink>
      <NavLink to="/report/start-new-journey" activeClassName="active">
        Start new journey
      </NavLink> */}
    </Div>
  );
};

export default ReportMenu;
