import {
  GET_DISC_BY_ID,
  GET_EXAM_USER_BY_ID,
  GET_QUICK_BY_ID,
  SET_UID_AND_USERID,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, patchAPI, deleteAPI } from "utils/api";

export const getRecommendById = (uid, userId) => (dispatch, getState) => {
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_USER_BY_ID.REQUEST });
  return getAPI({
    url: `examUser/${uid}?userID=${userId}`,
  })
    .then((data) =>
      dispatch({
        type: GET_EXAM_USER_BY_ID.SUCCESS,
        payload: data,
      })
    )
    .catch((error) => dispatch({ type: GET_EXAM_USER_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getQuickSummaryById =
  (uid, userId, reportConfId) => (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_QUICK_BY_ID.REQUEST });
    return getAPI({
      url: `report_quicksummary/${uid}?userID=${userId}&reportID=${reportConfId}`,
    })
      .then((data) =>
        dispatch({
          type: GET_QUICK_BY_ID.SUCCESS,
          payload: data,
        })
      )
      .catch((error) => dispatch({ type: GET_QUICK_BY_ID.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const getDiscSummaryById =
  (uid, userId, reportConfId) => (dispatch, getState) => {
    dispatch(showLoading());
    dispatch({ type: GET_DISC_BY_ID.REQUEST });
    return getAPI({
      url: `discsummary/${uid}?userID=${userId}&reportID=${reportConfId}`,
    })
      .then((data) =>
        dispatch({
          type: GET_DISC_BY_ID.SUCCESS,
          payload: data,
        })
      )
      .catch((error) => dispatch({ type: GET_DISC_BY_ID.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const setUIdUserIdAndReportConfId =
  (uid, userId, reportConfId) => (dispatch) => {
    dispatch({
      type: SET_UID_AND_USERID,
      payload: { uid: uid, userId: userId, reportConfId: reportConfId },
    });
  };
