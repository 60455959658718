import styled from "styled-components";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import ReportLayout from "../../components/layouts/ReportLayout";
import Button from "../../components/common/button/Button";
import NextButton from "../../components/common/button/NextButton";

import BarChart from "components/common/chart/BarChart";

import icon1 from "../../assets/images/report/icon-promise1.svg";
import icon2 from "../../assets/images/report/icon-promise2.svg";
import icon3 from "../../assets/images/report/icon-promise3.svg";
import icon4 from "../../assets/images/report/icon-promise4.svg";
import icon5 from "../../assets/images/report/icon-promise5.svg";

import imageChart from "../../assets/images/report/chart2.png";

import { getQuickSummaryById, getRecommendById } from "redux/actions/report";

const StyledBanner = styled.div`
  background: var(--off-black);
  padding: 72px 20px;

  > .wrapper {
    display: flex;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    font-family: "kelson_sansbold";
    text-transform: uppercase;
    color: var(--off-white);

    .left {
      flex: 1;

      .title {
        font-size: 46px;
        height: 56px;
        text-transform: uppercase;
      }

      .total-report {
        font-size: 16px;

        span {
          font-size: 160px;
          line-height: 192px;
        }
      }

      button {
        border: 1px solid var(--off-white);
        width: 330px;
      }
    }

    .right {
      flex: 1;
      margin-top: 200px;

      .catagory {
        font-size: 160px;
        line-height: 142px;

        span {
          font-size: 24px;
          line-height: 32px;
          vertical-align: top;
        }
      }

      button {
        margin-top: 32px;
        width: 320px;
      }
    }
  }
`;

const Banner = () => {
  const { quickSummary, uid, userId, reportConfId } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();
  const [quickData, setQuickData] = useState(quickSummary);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("USER_DATA"))
  );

  useEffect(() => {
    dispatch(
      getQuickSummaryById(
        uid ?? userData.uid,
        userId ?? userData.userId,
        reportConfId ?? userData.reportConfId
      )
    );
    dispatch(getRecommendById(uid ?? userData.uid, userId ?? userData.userId));
  }, []);

  useEffect(() => {
    setQuickData(quickSummary);
  }, [quickSummary]);

  return (
    <StyledBanner>
      <div className="wrapper">
        <div className="left">
          <div className="title">Matching score</div>
          <div className="total-report">
            <span>{quickData?.matching_score}%</span>
          </div>
        </div>
        <div className="right">
          <div className="catagory">
            {quickData?.percenttile_order}
            <span>{quickData?.ordinal_suffix}</span>
          </div>
          <Button
            // onClick={() => history.push("/report/snapshot-your-health")}
            invert
          >
            PECENTILE
          </Button>
        </div>
      </div>
    </StyledBanner>
  );
};

const CategoryLink = ({ children, ...props }) => {
  return (
    <HashLink className="category-link" {...props}>
      <FontAwesomeIcon icon={["fas", "arrow-right"]} />
      {children}
    </HashLink>
  );
};

const WrapperQuickSummary = styled.div`
  .summary-wrapper {
    display: flex;
    margin: 0 -64px;

    .left {
      flex: 1;
      margin: 0 64px;

      .sub-title {
        font-family: "kelson_sansbold";
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
      }

      .title {
        margin-top: 8px;
        font-family: "kelson_sansbold";
        font-size: 56px;
        line-height: 64px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .desc {
        margin-top: 16px;
        font-size: 20px;
      }
    }

    .right {
      flex: 1;
      margin: 0 64px;

      .row {
        display: flex;
        /* justify-content: space-between; */
        padding: 12px 0;
        border-top: 1px solid var(--off-black);

        &:first-child {
          border-top: none;
        }
        .amount {
          text-align: right;
        }
      }

      a {
        color: var(--off-black);
        text-decoration: none;

        svg {
          margin-right: 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .chart-wrapper {
    margin-top: 67px;
    .title {
      font-size: 22px;
    }

    .information-wrapper {
      display: flex;
      margin-top: 24px;

      .information {
        position: relative;
        flex: 1;
        font-size: 22px;
        padding-left: 32px;
        padding-right: 12px;

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          top: 0;
          left: -10px;
        }
      }
    }
  }

  .cetegory-wrapper {
    margin-top: 105px;

    .cetegory {
      display: flex;
      margin: 0 -8px;

      .left {
        flex: 1;
        margin: 0 8px 64px;
      }

      .right {
        flex: 1;
        margin: 0 8px 64px;
      }

      .title {
        margin-bottom: 24px;
        font-family: "kelson_sansbold";
        font-size: 56px;
        line-height: 56px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .btn-full-report {
        padding: 8px 24px;
        font-family: "kelson_sansbold";
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--off-black);
        border: 1px solid var(--off-black);
        text-decoration: none;
      }

      .information {
        position: relative;
        font-size: 24px;
        padding: 9px 8px 9px 32px;
        border-top: 1px solid var(--off-black);

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top: 15px;
          left: 6px;
        }
      }

      .detail {
        margin-top: 24px;
        font-size: 20px;
      }
    }
  }
`;

const WrapperPositionPercentile = styled.div`
  .section-bottom {
    padding-top: 20px;

    .section-wrapper {
      display: flex;
      margin: 0 -24px;

      .left {
        flex: 1;
        margin: 0 24px;
        font-family: "kelson_sansbold";
        font-size: 60px;
        line-height: 70px;
        text-transform: uppercase;
      }

      .right {
        flex: 1;
        margin: 0 24px;

        .content {
          flex: 1;
          font-family: "kelson_sansbold";
          font-size: 40px;
          line-height: 50px;
          text-transform: uppercase;
        }
        .sub-content {
          font-size: 24px;
          font-weight: lighter;
        }
        button {
          width: 100%;
          margin-top: 48px;
        }
      }
    }
  }
`;

const WrapperGraph = styled.div`
  .chart {
    margin-top: 55px;
    text-align: center;
    font-size: 24px;
    width: 95%;
    .chart-box {
      width: 100%;
      canvas {
        height: 40vh;
      }
    }
    img {
      width: 100%;
    }
  }
`;

const WrapperRecomment = styled.div`
  margin-top: 60px;
  text-align: center;

  > .title {
    font-family: "kelson_sansbold";
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
  }

  > .desc {
    margin: 16px auto 0;
    max-width: 656px;
    font-size: 20px;
  }

  > .research-list {
    display: flex;
    margin: 82px 100px 0 60px;
    align-items: baseline;
    justify-content: space-between;
    .list {
      text-align: center;

      img {
        width: 120px;
      }

      .label {
        margin-top: 36px;
        font-size: 24px;
        font-family: "kelson_sansbold";
        text-transform: uppercase;
      }

      .desc {
        margin-top: 4px;
        font-size: 18px;
      }
    }
  }

  > button {
    margin-top: 64px;
    width: 320px;
  }
`;

const QuickSummary = () => {
  const { quickSummary, uid, userId, reportConfId } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();
  const [quickData, setQuickData] = useState(quickSummary);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("USER_DATA"))
  );
  const [chartData, setChartData] = useState(
    JSON.parse(localStorage.getItem("CHART_DATA"))
  );

  useEffect(() => {
    getQuickSummaryById(
      uid ?? userData.uid,
      userId ?? userData.userId,
      reportConfId ?? userData.reportConfId
    );
  }, []);

  useEffect(() => {
    setQuickData(quickSummary);
  }, [quickSummary]);

  const DisplayResult = (result) => {
    if (result === "ดี") {
      return <div className="information green">{result}</div>;
    } else if (result === "ปานกลาง") {
      return <div className="information yellow">{result}</div>;
    }
    return <div className="information red">{result}</div>;
  };

  return (
    <ReportLayout header={<Banner />}>
      <WrapperQuickSummary>
        <div className="summary-wrapper">
          <div className="left">
            <div className="sub-title">Report</div>
            <div className="title">Quick </div>
            <div className="title"> Summary</div>
            <div className="desc">
              ผลการทดสอบของคุณ{" "}
              {`${quickData?.first_name || ""} ${quickData?.last_name || "-"}`}
              <br />
              ด้านทักษะ ความรู้ และบุคลิกภาพ
            </div>
          </div>
          <div className="right">
            <div className="row">
              <div className="category" style={{ width: "165px" }}>
                วิชา
              </div>
              <div className="category">คะแนน</div>
              <div className="amount" style={{ width: "100%" }}>
                คะแนน (คิดเป็น %)
              </div>
            </div>
            {quickData?.exam_result_summary?.map((it) => {
              if (it?.subject_title === "DISC") {
                return (
                  <div className="row">
                    <div className="category" style={{ width: "165px" }}>
                      <CategoryLink to="#sleep">
                        {it?.subject_title}
                      </CategoryLink>
                    </div>
                    <div className="category" style={{ width: "100%" }}>
                      {it?.score}/{it?.full_score}
                    </div>
                    <div className="amount" style={{ width: "100%" }}>{`${it?.result} ${it?.result_desc}`}</div>
                  </div>
                );
              }
              return (
                <div className="row">
                  <div className="category" style={{ width: "165px" }}>
                    <CategoryLink to="#sleep">{it?.subject_title}</CategoryLink>
                  </div>
                  <div className="category">
                    {it?.score}/{it?.full_score}
                  </div>
                  <div className="amount"  style={{ width: "100%" }}>{it?.result} %</div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="chart-wrapper">
          <div className="title">
            เพื่อให้คุณเข้าใจความสามารถอย่างรวดเร็ว
            ว่าผู้ทดสอบมีความสามารถโดดเด่นในด้านใด เราจึงแบ่งระดับความสามารถเป็น
            3 ระดับ และให้สัญลักษณ์กำกับไว้ดังนี้
          </div>
          <div className="information-wrapper">
            <div className="information red">
              สัญลักษณ์สีแดง คือ <br />
              “ผลคะแนนต่ำกว่าความต้องการ”
            </div>
            <div className="information yellow">
              สัญลักษณ์สีเหลือง คือ <br />
              “ผลคะแนนปานกลาง”
            </div>
            <div className="information green">
              สัญลักษณ์สีเขียว คือ <br />
              “ผลคะแนนดี น่าพึงพอใจ”
            </div>
          </div>
        </div> */}
        {/* {quickData?.exam_result_detail?.map((it) => {
          return (
            <div className="cetegory-wrapper">
              <div id={it?.subject_title} className="cetegory">
                <div className="left">
                  <div className="title">{it?.subject_title}</div>
                  <a
                    className="btn-full-report"
                    href={`/full-report/${it?.subject_title}`}
                    target="_blank"
                  >
                    วิเคราะห์คะแนนรายวิชา
                  </a>
                </div>
                <div className="right">
                  {DisplayResult(it?.lable)}

                  <div className="detail">
                    <p>{it?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })} */}
      </WrapperQuickSummary>
      <WrapperPositionPercentile>
        <div className="section-bottom">
          <div className="section-wrapper">
            <div className="left">
              ตำแหน่ง <br /> PERCENTILE <br /> ในรอบการทดสอบ
            </div>
            <div className="right">
              <div>
                <p className="content">
                  ตำแหน่ง PERCENTILE <br />
                  ของคุณ
                  {`${quickData?.first_name} ${quickData?.last_name}`}
                  <br />
                  อยู่ลำดับที่ {quickData?.percenttile_order}TH
                </p>
                <span className="sub-content">
                  จากผู้สอบทั้งหมด {quickData?.total_examer || 0} คน
                </span>
              </div>
            </div>
          </div>
        </div>
      </WrapperPositionPercentile>
      <WrapperGraph>
        <div className="chart">
          <div className="chart-box">
            <BarChart
              dataLabel={chartData?.exam_result_set}
              dataResult={chartData?.exam_user_result}
            />
          </div>

          <p>
            มี {quickData?.count_result_less} คนที่ได้คะแนนน้อยกว่าคุณ
            &nbsp;&nbsp;&nbsp;&nbsp;มี {quickData?.count_result_more}{" "}
            คนที่ได้คะแนนมากกว่าคุณ
          </p>
        </div>
      </WrapperGraph>
      {/* <WrapperRecomment>
        <div className="title">Recommentation</div>
        <div className="desc">
          คุณสมบัติของผู้สมัครรวมด้านทักษะความรู้และบุคลิกภาพ
          ตรงกับความต้องการของบริษัท {quickData?.matching_score}%
          "มีโอกาสปานกลาง"
          ที่จะเข้ากันได้กับตำแหน่งที่ว่างและสไตล์การทำงานขององค์กรอาจมีโอกาสที่จะทำให้การดำเนินงานขององค์กรล่าช้า
        </div>
        <div className="research-list">
          <div className="list">
            <img src={icon3} />
            <div className="label"> Ranking PERCENTILE</div>
            <div className="desc">{quickData?.ranking_percentile_summary}</div>
          </div>
          <div className="list">
            <img src={icon1} />
            <div className="label">personality </div>
            <div className="desc">{quickData?.personality_summary || "-"}</div>
          </div>

          <div className="list">
            <img src={icon4} />
            <div className="label">matching score </div>
            <div className="desc">{quickData?.maching_score_summary}</div>
          </div>
        </div>
        <Button
        // onClick={() => history.push("/report/learn-about-fushion")}
        >
          {quickData?.summary}
        </Button>
      </WrapperRecomment> */}
    </ReportLayout>
  );
};

export default QuickSummary;
