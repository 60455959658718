import styled from "styled-components";
import { useHistory } from "react-router-dom";

import ReportLayout from "../../components/layouts/ReportLayout";
import NextButton from "../../components/common/button/NextButton";

import imageBanner from "../../assets/images/report/banner-discover-yourself.png";

const Div = styled.div`
  > .title {
    font-family: "kelson_sansbold";
    font-size: 48px;
    line-height: 56px;
    text-transform: uppercase;
    color: var(--off-black);
    text-align: center;
  }

  > .sub-title {
    margin-top: 32px;
    font-family: "kelson_sansbold";
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
  }

  > .banner {
    margin-top: 32px;
    width: 100%;
  }

  > .desc {
    margin-top: 32px;
  }

  .section-bottom {
    margin-top: 56px;
    padding-top: 58px;
    border-top: 1px solid var(--off-black);

    .section-wrapper {
      display: flex;
      margin: 0 -24px;

      .left {
        flex: 1;
        margin: 0 24px;
        font-family: "kelson_sansbold";
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
      }

      .right {
        flex: 1;
        margin: 0 24px;

        button {
          width: 100%;
          margin-top: 48px;
        }
      }
    }
  }
`;

const ReportDiscoverYourself = () => {
  const history = useHistory();
  return (
    <ReportLayout>
      <Div>
        <div className="title">Your DNA makes you, you</div>
        <div className="sub-title">
          Secrets, disease and beauty are all written in the human genome, the
          complete set of genetic instructions needed to build a human being.
          Now, we have the power to read this complex code, predicting things
          like height, eye color, age and even facial structure
        </div>
        <img className="banner" src={imageBanner} />
        <div className="desc">
          <p>
            Human DNA is 99.9% identical from person to person. Although 0.1%
            difference doesn’t sound like a lot, it actually represents millions
            of different locations within the genome where variation can occur,
            equating to a breathtakingly large number of potentially unique DNA
            sequences. But how can nearly everyone have a unique DNA sequence if
            we inherit our DNA from our parents? Wouldn’t it stand to reason
            that our DNA is the same as theirs? In short, the answer is no.
            There are multiple ways our bodies ensure that we have a unique set
            of DNA that differs from our parents. For starters, you inherit two
            copies of each chromosome—one copy from your mom and one copy from
            your dad. This means that your genome (all of your DNA) is already
            different because it contains chromosomes from both of your parents.
            This can also help explain why two siblings appear to have different
            genetic ancestry, since they may get different chromosomes from
            their parents. But there is more to the story than the combination
            of chromosome pairs. The actual sequence of DNA on each of the
            chromosomes is unique due in part to recombination.
          </p>
          <p>
            Recombination is a process where sections of DNA are traded between
            the chromosomes that make up a pair. For example, chromosome 1 from
            your biological mother will be lined up next to chromosome 1 from
            your biological father. After recombination, the chromosomes will
            look somewhat like a quilt because they are made up of DNA from both
            parents (this process is depicted in the figure below). An important
            point to note here is that the total amount of DNA on each
            chromosome should not change in a significant way, because a portion
            of your mom’s chromosome was traded for the same portion on your
            dad’s chromosome.
          </p>
        </div>
        <div className="section-bottom">
          <div className="section-wrapper">
            <div className="left">
              Secrets, disease and beauty are all written in the human genome,
              the complete set of genetic instructions needed to build a human
              being
            </div>
            <div className="right">
              <div className="content">
                <p>
                  So if the chromosomes are trading the same sections of DNA,
                  how does this create a unique sequence? The human DNA sequence
                  consists of nearly 3 billion DNA base pairs. The order or
                  these base pairs is nearly identical from person to person,
                  but sometimes there are random changes in the sequence. We
                  call these changes variants. The combination of all of your
                  variants make up the 0.1% difference in your DNA—the part of
                  your DNA that makes you unique from everyone else—and helps
                  give you a unique sequence. This means when chromosome pairs
                  come together, the chromosomes you inherit from your mom are
                  slightly different from the chromosomes you inherit from your
                  dad thanks to the many DNA variants on each of the
                  chromosomes.
                </p>
                <p>
                  When recombination happens, the chromosomes are essentially
                  trading DNA variants amongst themselves. This process helps
                  drive evolution by creating a slightly new version of the DNA,
                  which may give your offspring a competitive advantage by
                  giving you variants that help you better metabolize nutrients
                  or blend in with your environment, for example. (Blending in
                  with the environment isn’t very important for modern humans,
                  but for many organisms, it is!)
                </p>
              </div>
              <NextButton onClick={() => history.push("/report/summary")}>
                A book of life
              </NextButton>
            </div>
          </div>
        </div>
      </Div>
    </ReportLayout>
  );
};

export default ReportDiscoverYourself;
