import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReportLayout from "../../components/layouts/ReportLayout";
import defaultImg from "../../assets/images/report/default-image.jpg";
import formatValue from "utils/formatValue";

import {
  getRecommendById,
  getDiscSummaryById,
  getQuickSummaryById,
  setUIdUserIdAndReportConfId,
} from "redux/actions/report";

import PopupPreviewImage from "../../containers/popup/PopupPreviewImage";

const Div = styled.div`
  display: flex;
  justify-content: space-between;

  > .test-detail {
    flex: 1;

    .profile-name,
    .test-name,
    .kit-name {
      font-family: "kelson_sansbold";
      text-transform: uppercase;
    }

    .profile-name {
      font-size: 56px;
      line-height: 64px;
      color: var(--off-black);
      font-weight: bold;
    }

    .test-name {
      margin-top: 8px;
      font-size: 32px;
      color: var(--text-body);
    }

    .test-summary {
      margin-top: 32px;
      font-size: 20px;
    }

    .test-kit {
      margin-top: 92px;

      .kit-name {
        font-size: 28px;
        color: var(--off-black);
        font-weight: bold;
      }

      .kit-data {
        font-size: 22px;
        margin-top: 16px;
      }
    }
  }

  > .information {
    flex: 1;
    max-width: 320px;
    text-align: right;
    margin-top: 360px;
    font-size: 14px;

    .img-desc {
      margin-top: 20px;
      margin-left: auto;
      width: 262px;
      height: 88px;
      background: var(--disabled);
    }

    .desc2 {
      margin-top: 16px;
    }

    .desc3 {
      display: flex;
      margin-top: 12px;
      justify-content: flex-end;
      align-items: center;
      text-align: left;

      .img {
        width: 56px;
        height: 56px;
        background: var(--disabled);
        border-radius: 50%;
        margin-right: 12px;
      }
    }

    > button {
      width: 100%;
      margin-top: 64px;
    }
  }

  .container-img {
    display: flex;
    flex-direction: row;
  }
  .title-header {
    font-size: 52px;
    font-weight: bold;
  }
  .date-range {
    font-size: 20px;
  }
`;
const DivImg = styled.div`
  margin-top: 35px;
  .container-img {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 20px;
    grid-gap: 10px;

    .title-img {
      background-color: black;
      margin: 10px 0;
      padding: 10px;
      color: white;
      font-size: 14px;
      text-align: center;
    }

    .item-img {
      img {
        width: 100%;
        border: 3px solid black;
      }

      .img-box {
        position: relative;
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          background: rgba(0, 0, 0, 0);
          transition: background 0.5s ease;
        }

        .btn-view {
          position: absolute;
          top: 50%;
          left: 50%;
          padding: 10px 20px;
          border: none;
          font-size: 18px;
          color: white;
          background-color: black;
          transform: translate(-50%, -50%);
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.35s ease;
          border-radius: 5px;
        }
      }
      .img-box:hover .overlay {
        display: block;
        background: rgba(0, 0, 0, 0.3);
        .btn-view {
          opacity: 1;
        }
      }
      .img-box:hover .btn-view {
        opacity: 1;
      }
    }
  }

  .title-header {
    font-size: 52px;
    font-weight: bold;
  }
`;
const Report = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { uid, userId, reportConfId } = useParams();
  const { report, quickSummary } = useSelector((state) => state.report);
  localStorage.setItem("CHART_DATA", JSON.stringify(quickSummary));

  const [isOpenImageModal, setIsOpenImageModal] = useState(false);
  const [path, setPath] = useState();

  useEffect(() => {
    dispatch(setUIdUserIdAndReportConfId(uid, userId, reportConfId));
    dispatch(getRecommendById(uid, userId));
    dispatch(getQuickSummaryById(uid, userId, reportConfId));
    localStorage.setItem(
      "USER_DATA",
      JSON.stringify({ uid: uid, userId: userId, reportConfId: reportConfId })
    );
  }, []);

  const handlePreviewImage = (value) => {
    setPath(value);
    setIsOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setIsOpenImageModal(false);
    setPath();
  };

  return (
    <ReportLayout>
      {Boolean(isOpenImageModal) && (
        <PopupPreviewImage
          open
          path={path}
          onClose={handleCloseImageModal}
        ></PopupPreviewImage>
      )}
      <Div>
        <div className="test-detail">
          <div className="profile-name">{report?.first_name}</div>
          <div className="profile-name">{report?.last_name}</div>
          <div className="test-name"> {report?.campaign_name}</div>
          <div className="test-summary">
            {report?.subject?.map((it) => {
              return (
                <div>
                  {it?.title} {it?.questions} ข้อ{" "}
                </div>
              );
            })}

            {/* <div>Logic 8 ข้อ </div>
            <div>English 10 ข้อ </div>
            <div>DISC 10 ข้อ </div> */}
          </div>
          <div className="test-kit">
            <div className="kit-name">Personal information</div>
            <div className="kit-data">
              <div>Email : {report?.email}</div>
              <div>Status : {report?.use_in}</div>
              <div>Start Time: {report?.start_time}</div>
              <div>Leave Time: {report?.end_time}</div>
              <div>Time in Session (minutes): {report?.used_time}</div>
              <div>Report Generate: {report?.generate_at}</div>
            </div>
          </div>
        </div>

        {/* <div className="information">
          <div className="desc">
            <div>DNA Sequencing with Fastest AI-GTX.ONE</div>
            <div>99.9% Accuracy of Analysis Results</div>
          </div>
          <div className="img-desc" />
          <div className="desc2">FUSION Genetic analysis of sleep</div>
          <div className="desc3">
            <div className="img" />
            <div className="text">
              <div>GUP Analysis</div>
              <div>Sleep Gene Analysis</div>
            </div>
          </div>
          <NextButton onClick={() => history.push("/report/disc")}>
            START YOUR JOUNEY
          </NextButton>
        </div> */}
      </Div>
      <DivImg>
        <div className="infomation-test-img">
          <div className="title-header">ภาพขณะทำการทดสอบ</div>
          <div className="date-range">
            ช่วงวันที่ <strong>{report?.start_time}</strong> -{" "}
            <strong>{report?.end_time}</strong>{" "}
          </div>

          <div className="container-img">
            <div className="item-img">
              <div className="title-img">
                Screenshot#1 - {report?.screenshot1?.emotion} -ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot1?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot1?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot1?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#2 - {report?.screenshot2?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot2?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot2?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot2?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#3 - {report?.screenshot3?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot3?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot3?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot3?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#4 - {report?.screenshot4?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot4?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot4?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot4?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#5 - {report?.screenshot5?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot5?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot5?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot5?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#6 - {report?.screenshot6?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot6?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot6?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot6?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#7 - {report?.screenshot7?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot7?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot7?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot7?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#8 - {report?.screenshot8?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot8?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot8?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot8?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#9 - {report?.screenshot9?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot9?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot9?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot9?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#10 - {report?.screenshot10?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot10?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot10?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot10?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#11 - {report?.screenshot11?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot11?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot11?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot11?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#12 - {report?.screenshot12?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot12?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot12?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot12?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#13 - {report?.screenshot13?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot13?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot13?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot13?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#14 - {report?.screenshot14?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot14?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot14?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot14?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#15 - {report?.screenshot15?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot15?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot15?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot15?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
            <div className="item-img">
              <div className="title-img">
                Screenshot#16 - {report?.screenshot16?.emotion} - ช่วงเริ่มทำ{" "}
                {formatValue(
                  "DateTime2",
                  report?.screenshot16?.snapshot_time_created
                )}
              </div>
              <div className="img-box">
                <img src={report?.screenshot16?.snapshot_url || defaultImg} />
                <div className="overlay"></div>
                <button
                  className="btn-view"
                  onClick={() =>
                    handlePreviewImage(report?.screenshot16?.snapshot_url)
                  }
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </DivImg>
    </ReportLayout>
  );
};

export default Report;
